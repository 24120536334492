import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { Hero } from "@/components/content/hero";
import { Feature } from "@/components/content/feature";
import { CardWrapper } from "@/components/content/card/card-wrapper";
import { TextWrapper } from "@/components/content/text";
import { Slideshow } from '@/components/content/slideshow'
import { ViewComponent } from '@/components/content/view'
import { ImageComponent } from '@/components/content/image'
import { InstagramPost } from '@/components/content/instagram-post'
import { YouTubeVideo } from '@/components/content/youtube'
import { VimeoVideo } from '@/components/content/vimeo'
import { Iframe } from '@/components/layout/content-components/serializers/iFrame'
import { PDF } from '@/components/layout/content-components/serializers/pdf'
import { OptionsTable } from "@/components/content/optionsTable";
import { FAQSection } from "@/components/content/faq";
import { CTASection } from "@/components/content/cta";

const DynamicComponentWithNoSSRTikTok = dynamic(
  () => import('@/components/content/tiktok-video'),
  { ssr: false }
)

const DynamicComponentWithNoSSR = dynamic(
  () => import('@/components/content/gallery'),
  { ssr: false }
)

import { config } from '@/lib/sanity.config'
import imageUrlBuilder from '@sanity/image-url'
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

const builder = imageUrlBuilder(config)

function ContentManager({ content, webform, view, filter, layout }) {
  // console.clear()
  // console.log({content})

  // Hero
  if (content._type === 'contentComponentHero') {
    return <Hero layout={content?.layout} tagline={content?.tagline} title={content?.title} text={content?.text} primaryLinkText={content?.callToActionPrimary?.title} primaryLinkUrl={content?.callToActionPrimary?.url} primaryLinkIcon={content?.callToActionPrimary?.icon?.name} primaryLinkType={content?.callToActionPrimary?.type} secondaryLinkText={content?.callToActionSecondary?.title} secondaryLinkUrl={content?.callToActionSecondary?.url} secondaryLinkIcon={content?.callToActionSecondary?.icon?.name} secondaryLinkType={content?.callToActionSecondary?.type} altText={content?.heroImage?.image?.alt} fullWidth={content?.fullWidth} image={content?.heroImage?.image} adjustedHeight={content?.height} />
    // Feature
  } else if (content._type === 'contentComponentFeature') {
    return <Feature layout={content.layout} title={content.title} tagline={content.tagline} text={content.text} featuretteStyle={content.featuretteLayout} featurettes={content.featurettes} centerAlign={content?.centerAlign} />
    // Cards
  } else if (content._type === 'contentComponentCardWrapper') {
    return <CardWrapper layout={content.layout} title={content.title} tagline={content.tagline} text={content.text} cards={content.cards} centerAlign={content?.centerAlign} />
    // Image
  } else if (content._type === 'mainImage') {
    return <ImageComponent content={content} />
    // Image Gallery
  } else if (content._type === 'imageGallery') {
    return <DynamicComponentWithNoSSR images={content.images} centerAlign={content?.centerAlign} />
    // Image Gallery
  } else if (content._type === 'slideshow') {
    return <Slideshow slides={content.slides} />
    // Text Block
  } else if (content._type === 'contentComponentTextBlock') {
    return <TextWrapper body={content.body} fullWidth={content?.fullWidth || false} layout={layout || false} centerAlign={content?.centerAlign || false} />
    // View Component
  } else if (content._type === 'contentComponentView') {
    return <ViewComponent contentView={content.contentView} webform={webform} view={view} filter={filter} centerAlign={content?.centerAlign} />
    // YouTube Video
  } else if (content._type === 'youtube') {
    return <YouTubeVideo url={content?.url} maxWidth={content?.maxWidth} centerAlign={content?.centerAlign} />
    // Vimeo Video
  } else if (content._type === 'vimeo') {
    return <VimeoVideo url={content?.url} maxWidth={content?.maxWidth} centerAlign={content?.centerAlign} />
    // Instagram Post
  } else if (content._type === 'instagramPost') {
    return <InstagramPost url={content.url} />
    // TikTok Video
  } else if (content._type === 'tikTokVideo') {
    return <DynamicComponentWithNoSSRTikTok url={content.url} />
    // Iframe
  } else if (content._type === 'iframe' && content?.url) {
    if (content?.centerAlign) {
      return <div className="w-full max-w-4xl px-3 mx-auto lg:px-0"><section className="mb-8 md:mb-12 lg:mb-16"><Iframe {...content} /></section></div>
    } else {
      return <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper"><Iframe {...content} /></section>
    }
    // PDF Embed
  } else if (content._type === 'pdfFile' && content.file && content.file.asset && content.file.asset._ref) {
    if (content?.centerAlign) {
      return <div className="w-full max-w-4xl px-3 mx-auto lg:px-0"><section className="mb-8 md:mb-12 lg:mb-16"><PDF {...content} /></section></div>
    } else {
      return <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper"><PDF {...content} /></section>
    }

    // PDF Embed
  } else if (content._type === 'pdfFile' && content.file && content.file.asset && content.file.asset._ref) {
    return <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper"><PDF {...content} /></section>
    // Anchor
  } else if (content._type === 'anchor' && content?.anchorID?.current) {
    return <span id={content?.anchorID?.current}></span>
    // Options Table
  } else if (content._type === 'contentComponentOptionsTable') {
    return <OptionsTable layout={content?.layout} title={content?.title} tagline={content?.tagline} text={content?.text} icon={content?.listIcon} optionLayout={content?.optionLayout} optionListTitle={content?.optionListTitle} optionItems={content?.optionsItems} centerAlign={content?.centerAlign} />
    // FAQ Section
  } else if (content._type === 'contentComponentFAQSection') {
    return <FAQSection layout={content?.layout} title={content?.title} tagline={content?.tagline} text={content?.text} faqsLayout={content?.faqsLayout} faqs={content?.faqs} centerAlign={content?.centerAlign} />
    // CTA Section
  } else if (content._type === 'contentComponentCTASection') {
    return <CTASection
      layout={content?.layout}
      centerAlign={content?.centerAlign}
      title={content?.title}
      subTitle={content?.subTitle}
      text={content?.text}
      callToActionPrimary={content?.callToActionPrimary}
      callToActionSecondary={content?.callToActionSecondary}
    />
  } else {
    return null
  }
}
// "componentViewPosts"
export function ContentComponents(props: { contentComponents: any; webform: any; view: any; filter: any; layout: boolean; }) {
  const { contentComponents, webform, view, filter, layout } = props
  return (
    <>
      {contentComponents?.map((content: { _key: string }) => (
        <ContentManager key={content._key} content={content} webform={webform} view={view} filter={filter} layout={layout} />
      ))}
    </>
  )
}

ContentComponents.propTypes = {
  contentComponents: PropTypes.array,
  webform: PropTypes.object,
  view: PropTypes.object,
  filter: PropTypes.string,
  layout: PropTypes.bool,
}