import PropTypes from 'prop-types'
import Image from 'next/image'


Logo.propTypes = {
  classes: PropTypes.string,
  alt: PropTypes.string,
  mode: PropTypes.string || 'light'
}

export function Logo(props: {classes: string; alt: string; mode: string;}) {
  const { classes, alt, mode } = props;
  const logoColor = mode === 'dark' ? '#fff' : '#111'
  return (
    <Image src="/images/logo.png" alt="me" width="310" height="105" />
  )
}
