import React, { useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Linker } from '@/components/content/linker'
import { useUser } from '@/utils/useUser';

import dynamic from 'next/dynamic'
const DynamicComponentWithNoSSR = dynamic(
  () => import('@/components/layout/header/snipcart'),
  { ssr: false }
)

export function MobileNav({ siteNav, topNav, appLoginEnabled }) {
  const [linkState, setLinkState] = useState(false)
  const wrapperRef = useRef(null);
  const Router = useRouter();
  const { isLoading, subscription, userDetails } = useUser();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLinkState(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const handleClick = () => {
    setLinkState(linkState => !linkState);
  }
  return (
    <>
      <div className="-my-2 lg:hidden">
        <button type="button" aria-expanded={linkState} onClick={() => handleClick()} className="inline-flex items-center justify-center p-2.5 bg-white border border-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span className="sr-only">Open menu</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="w-6 h-6 text-gray-500"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
        </button>
      </div>

      <nav className={`${linkState === true ? 'visible transform opacity-100 flex-col flex w-full mt-6 lg:hidden' : 'transform opacity-0 hidden lg:hidden'}`}>

        <ul className='lg:hidden'>
          {siteNav.map(link => {
            if (link?.links?.length >= 1) {
              return (
                <li className="pb-4 mb-4 border-b-2 border-gray-100 last:border-none last:pb-0 last:mb-0" key={link._key}>
                  <p className="pt-1 pb-3 text-sm font-bold tracking-widest text-gray-700 uppercase">{link.title}</p>
                  <div className="grid md:grid-cols-2 gap-y-4 gap-x-8">
                    {link.links.map(link =>
                      <Linker key={link._key} url={link.siteLink} classes="text-base font-medium text-gray-500 hover:text-gray-900">{link.title}</Linker>
                    )}

                  </div>
                </li>
              )
            } else {
              return (
                <li className="pb-4 mb-4 border-b-2 border-gray-100 last:border-none last:pb-0 last:mb-0" key={link._key}>
                  <Linker url={link.siteLink} classes="mb-6 text-base font-medium text-gray-500 hover:text-gray-900">{link.title}
                  </Linker>
                </li>
              )
            }
          }
          )}
        </ul>


        <nav className='w-full pt-4 mt-4 border-t-2 border-gray-100'>
          <ul className="grid mb-6 sm:grid-cols-2 gap-y-4 gap-x-8">
          {topNav?.map(link => {
            if (link?.links?.length >= 1) {
              return (
                <li className="pb-4 mb-4 border-b-2 border-gray-100 last:border-none last:pb-0 last:mb-0" key={link._key}>
                  <p className="pt-1 pb-3 text-sm font-bold tracking-widest text-gray-700 uppercase">{link.title}</p>
                  <div className="grid md:grid-cols-2 gap-y-4 gap-x-8">
                    {link.links.map(link =>
                      <Linker key={link._key} url={link.siteLink} classes="text-base font-medium text-gray-500 hover:text-gray-900">{link.title}</Linker>
                    )}

                  </div>
                </li>
              )
            } else {
              return (
                <li className="pb-4 mb-4 border-b-2 border-gray-100 last:border-none last:pb-0 last:mb-0" key={link._key}>
                  <Linker url={link.siteLink} classes="mb-6 text-base font-medium text-gray-500 hover:text-gray-900">{link.title}
                  </Linker>
                </li>
              )
            }
          }
          )}
          </ul>

          <ul className="grid grid-cols-2 mt-4 gap-y-4 gap-x-8">

          {/* SUPABASE LINKS */}
           {(process?.env?.NEXT_PUBLIC_SUPABASE_URL && process?.env?.NEXT_PUBLIC_SUPABASE_KEY && appLoginEnabled === true) && <>

            {(!isLoading && userDetails) && (<>
              <li className='col-span-2 sm:col-span-1'><Link href='/app/dashboard'><a className='block w-full px-12 py-2 text-base font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm whitespace-nowrap hover:bg-indigo-700'>Dashboard</a></Link></li>

              <li className='col-span-2 sm:col-span-1'><Linker url='/api/auth/logout' classes='block text-center px-12 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full'>Logout</Linker></li>
            </>)}

            {(!isLoading && !userDetails) && (<>
              <li className='col-span-2 sm:col-span-1'><Link href='/app/login'><a className='block w-full px-12 py-2 text-base font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm whitespace-nowrap hover:bg-indigo-700'>Login</a></Link></li>
              <li className='col-span-2 sm:col-span-1'><Linker url='/app/join' classes='block text-center px-12 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full'>Join</Linker></li>
            </>)}


            </>}

            {/* SNIPCART CART */}
            {process?.env?.NEXT_PUBLIC_SNIPCART_PUB_API_KEY &&
              <li className='flex items-center col-span-2'>
                <span className='block w-full px-4 py-2 text-base font-medium text-center text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                  <DynamicComponentWithNoSSR />
                </span>
              </li>}
          </ul>
        </nav>
      </nav>
    </>
  )
}
