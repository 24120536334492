import PropTypes from 'prop-types'
import { Contact } from '@/components/content/view/contact'
import { JobApp } from '@/components/content/view/job-app'
import { ContentView } from '@/components/content/view/content-view/'
import { TeamView } from '@/components/content/view/team/'
import { Subscribe } from '@/components/services/mailchimp/subscribe'
import { UpcomingEvents } from '@/components/content/view/events/'
import { LatestPosts } from '@/components/content/view/posts/'
import { FeaturedPosts } from '@/components/content/view/featured-posts/'

ViewComponent.propTypes = {
  contentView: PropTypes.string.isRequired,
  webform: PropTypes.object,
  view: PropTypes.object,
  filter: PropTypes.string,
  centerAlign: PropTypes.bool
}

export function ViewComponent(props: { contentView: string, webform: any; view: any; filter: any; centerAlign: boolean }) {
  const { contentView, webform, view, filter, centerAlign } = props

  if (centerAlign) {

    if (contentView === 'componentViewContact') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <Contact webform={webform} />
        </section></div>
      )
    } else if (contentView === 'componentViewJobApp') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <JobApp webform={webform} />
        </section></div>
      )
    } else if (contentView === 'componentViewPosts' || contentView === 'componentViewEvents') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <ContentView view={view} />
        </section></div>
      )
    } else if (contentView === 'componentViewTeam') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <TeamView view={view} />
        </section></div>
      )
    } else if (contentView === 'componentViewLatestPosts') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <LatestPosts />
        </section></div>
      )
    } else if (contentView === 'componentViewUpcomingEvents') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <UpcomingEvents />
        </section></div>
      )
    } else if (contentView === 'componentMCSubForm') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          {/* <Subscribe /> */}
        </section></div>
      )
    } else if (contentView === 'componentViewFeaturedPosts') {
      return (<div className="w-full max-w-4xl px-3 mx-auto lg:px-0">
        <section className="mb-8 md:mb-12 lg:mb-16">
          <FeaturedPosts />
        </section></div>
      )
    } else {
      return null
    }

  } else {

    if (contentView === 'componentViewContact') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <Contact webform={webform} />
        </section>
      )
    } else if (contentView === 'componentViewJobApp') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <JobApp webform={webform} />
        </section>
      )
    } else if (contentView === 'componentViewPosts' || contentView === 'componentViewEvents') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <ContentView view={view} />
        </section>
      )
    } else if (contentView === 'componentViewTeam') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <TeamView view={view} />
        </section>
      )
    } else if (contentView === 'componentViewLatestPosts') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <LatestPosts />
        </section>
      )
    } else if (contentView === 'componentViewUpcomingEvents') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <UpcomingEvents />
        </section>
      )
    } else if (contentView === 'componentMCSubForm') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          {/* <Subscribe /> */}
        </section>
      )
    } else if (contentView === 'componentViewFeaturedPosts') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <FeaturedPosts />
        </section>
      )
    } else {
      return null
    }
  }
}
