import { useState, useEffect } from 'react'
import groq from 'groq'
import { getClient } from '@/lib/sanity.server'
import { Teaser } from '@/components/content/view/posts/teaser'
import { Grid } from "@/components/content/grid";
import { Linker } from '@/components/content/linker'
import { HiChevronRight } from "react-icons/hi";

export function FeaturedPosts() {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const query = groq`{
    "posts": *[_type == "featuredPosts" ][0]{
      "featured": featured[]->{
        _id,
        title,
        publishedAt,
        category->{_id, title, "slug": slug.current},
        mainImage,
        "slug": slug.current,
        summary
      }
    },
  }`

  useEffect(() => {
    async function GetPosts() {
      let fetchPosts = { posts: { featured: [] } }
      try {
        fetchPosts = await getClient().fetch(query)
      } catch (error) {
        console.log({ error })
      }
      if (fetchPosts) {
        // console.log(fetchPosts)
        setPosts(fetchPosts?.posts?.featured)
      }
      setLoading(false)
    }
    GetPosts()
  }, [query])

  const EventSkeleton = () => {
    return (
      <article className='flex flex-col overflow-hidden rounded-md shadow-md'>
        {/* Image */}
        <div className="relative flex-shrink-0 overflow-hidden">
          <figure className='bg-slate-300 w-full h-4 pt-[48.78%] relative'>
          </figure>
        </div>

        <div className="flex flex-col justify-between flex-1 px-6 pt-4 pb-6 bg-white">
          <div className="flex-1">
            {/* Category */}
            <div className="animate-pulse"><div className='w-1/4 h-3 rounded-md bg-slate-300 mb-'></div></div>
            {/* Title */}
            <div className="mt-4 mb-3 animate-pulse"><div className='w-1/2 h-6 rounded-md bg-slate-300'></div></div>
            {/* Summary */}
            <div className="mb-2 animate-pulse"><div className='w-full h-3 rounded-md bg-slate-300'></div></div>
            <div className="mb-2 animate-pulse"><div className='w-full h-3 rounded-md bg-slate-300'></div></div>
            <div className="mb-2 animate-pulse"><div className='w-full h-3 rounded-md bg-slate-300'></div></div>
          </div>
        </div>
      </article>
    )
  }

  return (
    <div>
      {(!loading && posts && posts.length >= 1) &&
        (<><Grid gridSize={3}>
          {posts.map(e => <Teaser post={e} key={e._id} />)}
        </Grid>
          <div className='flex justify-end mt-8'>
            <p className='inline-flex items-center space-x-1'>
              <Linker url='/our-work' classes='font-semibold text-gray-600 transition-all cursor-pointer hover:text-gray-800'>View Our Work</Linker>
              <span className='text-xl'><HiChevronRight /></span>
            </p>
          </div>
        </>)}

      {(loading)
        && (<Grid gridSize={3}>
          <EventSkeleton />
          <EventSkeleton />
          <EventSkeleton />
        </Grid>)}

      {(!loading && (!posts || posts?.length < 1)) && (
        <div>
          <p>Currently there are no featured news.</p>
        </div>
      )}
    </div>
  )
}
